function getAuthorizationCallbackCookie() {
    const value = "; " + document.cookie;
    const parts = value.split('; AuthorizationCallback=');
    let result = '';
    if (parts.length === 2) result = parts.pop().split(';').shift();
    return result === "true";
}

function getWidgetUrl() {
    var env = localStorage.getItem('AUTH_ENV');
    if (env === 'dev' || env === 'tst') {
        return 'https://auth-widget.finam.dev/cdn/@8.js';
    }
    return 'https://libs-cdn.finam.ru/auth-widget/@8.js';
}

function run() {
    if (window.TxGlobalAuthInst) {
        return;
    }

    var processTokenUrl = localStorage.getItem('PROCESS_TOKEN_URL');
    var env = localStorage.getItem('AUTH_ENV');
    TxGlobalAuth.init({
        appName: 'Partner',
        appVersion: '1.0.0',
        env: env,
    }).then(() => {
        console.log((new Date()).toLocaleString() + ' widget initialized');

        // Подписка на изменение токена
        TxGlobalAuth.subscribeJWT((response) => {
            console.log((new Date()).toLocaleString() + ' subscribeJWT called');

            window.TxGlobalAuthInst = TxGlobalAuth;
            const formRoot = document.getElementById('loginPage');
            if (formRoot) {
                var noAgetnMessage = document.getElementById("noAgent");
                if (noAgetnMessage) {
                    if (TxGlobalAuth.isLoggedIn() && !getAuthorizationCallbackCookie()) {
                        noAgetnMessage.style.display = "block";
                    }
                    else {
                        noAgetnMessage.style.display = "none";

                        TxGlobalAuth.authenticate({
                            // т.к. для вашего проекта нужно показать виджет не в модальном окне, а в кастомном элементе
                            mountInline: {
                                prepareContainer: () => formRoot
                            }
                        })
                        console.log((new Date()).toLocaleString() + ' widget mounted');
                    }
                }
            }

            if (response) {
                var tokenInStorage = localStorage.getItem('token');
                if (!tokenInStorage || tokenInStorage != response.token) {
                    console.log((new Date()).toLocaleString() + ' sending ajax to validate token');
                    $.ajax({
                        url: processTokenUrl,
                        xhrFields: {
                            withCredentials: true
                        },
                        method: 'POST',
                        data: { "jwt": response.token },
                    }).done(successCallback).fail(errorCallback);
                }
            }

            console.log((new Date()).toLocaleString() + ' subscribeJWT called end');

            // begin_region Callbacks ---------->
            function successCallback(url) {
                console.log((new Date()).toLocaleString() + ' successCallback');
                localStorage.setItem('token', response.token);
                if (url != "") {
                    if (window.opener == null) {
                        window.location.href = url;
                    } else {
                        window.close();
                        window.opener.location.href = url;
                    }
                }
            }

            function errorCallback() {
                console.log((new Date()).toLocaleString() + ' errorCallback');
                window.location.href = localStorage.getItem('AUTH_ERR_URL');
            }
            // <---------- end_region Callbacks
        });
    });
}

$(document).ready(() => {
    const script = document.createElement('script');
    script.src = getWidgetUrl();
    script.async = true;
    script.onload = () => {
        console.log((new Date()).toLocaleString() + ' script loaded');
        TxGlobalAuth.debug.enableLogging()
        run();
    }
    document.body.appendChild(script);

    // вызывается в пунктах меню Выход, и перебрасывает на action Account/Logout
    window.Logout = function (url) {
        localStorage.removeItem('token');
        TxGlobalAuth.logout().then(() => {
            window.location.href = url;
        });
    };
});